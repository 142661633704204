<template>
  <el-container>
    <el-header height="180px"></el-header>
    <el-main>
      <el-row>
        <el-col>
          <h2>Steam自助注册</h2>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="spanValue" :offset="offsetValue">
          <el-container id="content">
            <el-main v-loading.fullscreen.lock="loading">
              <el-link
                target ="_blank"
                type="danger"
                :underline="false"
                href="https://www.bilibili.com/video/BV131421277r/?share_source=copy_web&vd_source=39369fb17f08034066f66058019fb03b"
                >点此查看视频教程</el-link>
                <br />
              <br />
              <el-alert title="欢迎使用Steam自助注册" type="warning">
              </el-alert>
              <br />
              <br />
              <el-form ref="form" :model="form" :rules="dataRule">
                <div style="margin-bottom: 20px; float: left">兑换码</div>
                <el-form-item prop="cdk">
                  <el-input
                    v-model="form.cdk"
                    placeholder="请输入兑换码"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="extractGift()"
                    >下一步</el-button
                  >
                </el-form-item>
              </el-form></el-main
            >
          </el-container>
        </el-col>
      </el-row>
      <div class="empty"></div>
    </el-main>
  </el-container>
</template>
<script>
export default {
  name: "index",
  data() {
    return {
      spanValue: 0,
      offsetValue: 0,
      activeName: "first",
      loading: false,
      showTable: false,
      form: {
        cdk: "",
      },
      dataRule: {
        cdk: [{ required: true, message: "兑换码不能为空", trigger: "blur" }],
      },
    };
  },
  mounted: function () {
    if (this._isMobile()) {
      this.spanValue = 24;
      this.offsetValue = 0;
    } else {
      this.spanValue = 10;
      this.offsetValue = 7;
    }
  },
  created() {
    if (this.$route.query.cdk) {
      this.form.cdk = this.$route.query.cdk;
    }
  },
  methods: {
    // 判断移动端还是pc端
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    onSubmit() {},
    extractGift() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$http
            .get("guest/checkCdk/" + this.form.cdk)
            .then(({ data }) => {
              if (data && data.code === 0) {
                this.loading = false;
                if (data.result.status === 1) {
                  this.$router.push({
                    path: "/submit-email",
                    query: {
                      cdk: this.form.cdk,
                      region: data.result.region,
                    },
                  });
                } else if (data.result.status === 2) {
                  this.$router.push({
                    path: "/email-verify",
                    query: {
                      cdk: this.form.cdk,
                    },
                  });
                } else if (data.result.status === 3) {
                  this.$router.push({
                    path: "/fill-info",
                    query: {
                      cdk: this.form.cdk,
                    },
                  });
                } else if (data.result.status === 4) {
                  this.$router.push({
                    path: "/complete",
                    query: {
                      cdk: this.form.cdk,
                    },
                  });
                } else {
                  this.$message.error("正在处理中...");
                }
              } else {
                this.loading = false;
                this.$message.error(data.msg);
              }
            })
            .catch((e) => {
              this.loading = false;
              this.$message({
                message: "网络或程序异常！" + e,
                type: "error",
              });
            });
        }
      });
    },
  },
};
</script>

<style>
body {
  background-image: url("../assets/background.jpg");
}
.el-header,
.el-footer {
  text-align: center;
  line-height: 80px;
}

.el-main {
  color: #b3c0d1;
  text-align: center;
}
#content {
  min-height: 230px;
  height: auto;
  box-sizing: border-box;
  background-color: #181a21;
  padding: 34px 42p;
}
.el-main span {
  text-align: left;
}
#main-col {
  background-color: #181a21;
  height: 250px;
}
/* body > .el-container {
    margin-bottom: 40px;
  } */

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
el-card {
  padding: 0px;
}
.empty,
el-footer {
  height: 100px;
}
/* 包裹箭头效果的盒子 */
.arrowSS {
  margin: 0 auto;
  animation: bounce-inSS 2s infinite; /* 启动动画特效 */
}
/* 箭头效果的盒子 */
.arrowSS_style {
  display: block;
  margin: 0 auto;
  width: 25px;
  height: 25px;
  border-right: 3px solid red;
  border-top: 3px solid red;
  -webkit-transform: rotate(135deg); /* 箭头方向可以自由切换角度 */
  transform: rotate(135deg);
  cursor: pointer;
}
/* 箭头动画 */
@keyframes bounce-inSS {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
</style>
