<template>
  <el-container>
    <el-header height="180px"></el-header>
    <el-main>
      <el-row>
        <el-col>
          <h2>Steam自助注册</h2>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="spanValue" :offset="offsetValue">
          <el-container id="content">
            <el-main v-loading.fullscreen.lock="loading">
              <el-link
                target ="_blank"
                type="danger"
                :underline="false"
                href="https://www.bilibili.com/video/BV131421277r/?share_source=copy_web&vd_source=39369fb17f08034066f66058019fb03b"
                >点此查看视频教程</el-link>
                <br />
              <br />
              <el-alert title="@sina、@yeah、@189等邮箱不支持" type="warning">
              </el-alert>
              <br />
              <br />
              <el-form ref="form" :model="form" :rules="dataRule">
                <div style="margin-bottom: 20px; float: left">请输入邮箱</div>
                <el-form-item prop="email">
                  <el-input
                    v-model="form.email"
                    placeholder="请输入邮箱"
                  ></el-input>
                </el-form-item>
                <div style="margin-bottom: 20px; float: left">
                  请选择注册地区
                </div>
                <el-form-item prop="region">
                  <el-autocomplete
                    style="width: 100%"
                    class="inline-input"
                    v-model="state2"
                    :fetch-suggestions="querySearch"
                    placeholder="请选择注册地区"
                    clearable
                    @clear="clearRegion"
                    @select="gameSelectChange"
                    :disabled="changeRegionFlag"
                  ></el-autocomplete>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="extractGift()"
                    >下一步</el-button
                  >
                </el-form-item>
              </el-form></el-main
            >
          </el-container>
        </el-col>
      </el-row>
      <div class="empty"></div>
    </el-main>
  </el-container>
</template>
  <script>
export default {
  name: "submit-email",
  data() {
    return {
      changeRegionFlag: false,
      restaurants: [],
      state2: "",
      spanValue: 0,
      offsetValue: 0,
      activeName: "first",
      loading: false,
      showTable: false,
      form: {
        cdk: "",
        email: "",
        region: null,
      },
      queryForm: {
        queryTbOid: "",
      },
      dataRule: {
        cdk: [{ required: true, message: "兑换码不能为空", trigger: "blur" }],
        email: [{ required: true, message: "邮箱不能为空", trigger: "blur" }],
        region: [
          { required: true, message: "注册地区不能为空", trigger: "blur" },
        ],
      },
    };
  },
  mounted: function () {
    if (this._isMobile()) {
      this.spanValue = 24;
      this.offsetValue = 0;
    } else {
      this.spanValue = 10;
      this.offsetValue = 7;
    }
  },
  created() {
    this.restaurants = this.loadAll();
    if (this.$route.query.cdk) {
      this.form.cdk = this.$route.query.cdk;
    }
    if (this.$route.query.region && this.$route.query.region !== "ANY") {
      this.form.region = this.$route.query.region;
      this.state2 = this.restaurants.find(
        (item) => item.code === this.form.region
      ).value;
      this.changeRegionFlag = true;
    }
  },
  methods: {
    clearRegion() {
      this.form.region = "";
    },
    // 判断移动端还是pc端
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    onSubmit() {},
    handleClick(tab, event) {},
    extractGift() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: "提交数据中,请勿刷新",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          this.$http
            .post("guest/register", this.form)
            .then(({ data }) => {
              if (data && data.code === 0) {
                loading.close();
                this.$router.push({
                  path: "/email-verify",
                  query: {
                    cdk: this.form.cdk,
                  },
                });
              } else {
                loading.close();
                this.$message.error(data.msg);
              }
            })
            .catch((e) => {
              this.loading = false;
              this.$message({
                message: "网络或程序异常！" + e,
                type: "error",
              });
            });
        }
      });
    },
    gameSelectChange(item) {
      this.$refs.form.fields.find(
        (item) => item.prop === "region"
      ).validateState = "success";
      this.$refs.form.fields.find(
        (item) => item.prop === "region"
      ).validateMessage = "";
      this.form.region = item.code;
    },
    loadAll() {
      return [
        { code: "CN", value: "中国大陆" },
        { code: "IN", value: "印度" },
        { code: "ID", value: "印度尼西亚" },
        { code: "JP", value: "日本" },
        { code: "KR", value: "韩国" },
        { code: "HK", value: "中国香港" },
        { code: "PH", value: "菲律宾" },
        { code: "SG", value: "新加坡" },
        { code: "VN", value: "越南" },
        { code: "MM", value: "缅甸" },
        { code: "TH", value: "泰国" },
        { code: "MY", value: "马来西亚" },
        { code: "TW", value: "中国台湾" },
        { code: "BH", value: "巴林" },
        { code: "SE", value: "瑞典" },
        { code: "JO", value: "约旦" },
        { code: "AM", value: "亚美尼亚" },
        { code: "AZ", value: "阿塞拜疆" },
        { code: "TR", value: "土耳其" },
        { code: "KZ", value: "哈萨克斯坦" },
        { code: "KG", value: "吉尔吉斯斯坦" },
        { code: "MO", value: "中国澳门" },
        { code: "BN", value: "文莱" },
        { code: "KH", value: "柬埔寨" },
        { code: "LA", value: "老挝" },
        { code: "GB", value: "英国" },
        { code: "FR", value: "法国" },
        { code: "RU", value: "俄罗斯" },
        { code: "IT", value: "意大利" },
        { code: "DE", value: "德国" },
        { code: "LU", value: "卢森堡" },
        { code: "BY", value: "白俄罗斯" },
        { code: "BE", value: "比利时" },
        { code: "AT", value: "奥地利" },
        { code: "ES", value: "西班牙" },
        { code: "IE", value: "爱尔兰" },
        { code: "FI", value: "芬兰" },
        { code: "PT", value: "葡萄牙" },
        { code: "LV", value: "拉脱维亚" },
        { code: "PL", value: "波兰" },
        { code: "LT", value: "立陶宛" },
        { code: "HU", value: "匈牙利" },
        { code: "NL", value: "荷兰" },
        { code: "CH", value: "瑞士" },
        { code: "CZ", value: "捷克共和国" },
        { code: "NO", value: "挪威" },
        { code: "GR", value: "希腊" },
        { code: "UA", value: "乌克兰" },
        { code: "DK", value: "丹麦" },
        { code: "RO", value: "罗马尼亚" },
        { code: "US", value: "美国" },
        { code: "CA", value: "加拿大" },
        { code: "JM", value: "牙买加" },
        { code: "MX", value: "墨西哥" },
        { code: "BR", value: "巴西" },
        { code: "AR", value: "阿根廷" },
        { code: "CO", value: "哥伦比亚" },
        { code: "CL", value: "智利" },
        { code: "VE", value: "委内瑞拉" },
        { code: "PE", value: "秘鲁" },
        { code: "AU", value: "澳大利亚" },
        { code: "MG", value: "马达加斯加" },
        { code: "MZ", value: "莫桑比克" },
        { code: "ZA", value: "南非" },
        { code: "ET", value: "埃塞俄比亚" },
        { code: "KE", value: "肯尼亚" },
        { code: "GH", value: "加纳" },
        { code: "NG", value: "尼日利亚" },
        { code: "MA", value: "摩洛哥" },
      ];
    },
    querySearch(queryString, cb) {
      // 调用 callback 返回建议列表的数据
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
  },
};
</script>
  
  <style>
body {
  background-image: url("../assets/background.jpg");
}
.el-header,
.el-footer {
  text-align: center;
  line-height: 80px;
}

.el-main {
  color: #b3c0d1;
  text-align: center;
}
#content {
  min-height: 230px;
  height: auto;
  box-sizing: border-box;
  background-color: #181a21;
  padding: 34px 42p;
}
.el-main span {
  text-align: left;
}
#main-col {
  background-color: #181a21;
  height: 250px;
}
/* body > .el-container {
      margin-bottom: 40px;
    } */

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
el-card {
  padding: 0px;
}
.empty,
el-footer {
  height: 100px;
}
</style>
  