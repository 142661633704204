<template>
  <el-container>
    <el-header height="180px"></el-header>
    <el-main>
      <el-row>
        <el-col>
          <h2>Steam自助注册</h2>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="spanValue" :offset="offsetValue">
          <el-container id="content">
            <el-main>
              <el-link
                target ="_blank"
                type="danger"
                :underline="false"
                href="https://www.bilibili.com/video/BV131421277r/?share_source=copy_web&vd_source=39369fb17f08034066f66058019fb03b"
                >点此查看视频教程</el-link>
                <br />
              <br />
              <el-alert
                title="帐户名称（长度至少为 3 个字符，且仅使用 a-z、A-Z、0-9 或 _ 字符）"
                type="warning"
              >
              </el-alert>
              <br />
              <el-alert title="帐户密码（长度至少为 8 个字符）" type="warning">
              </el-alert>
              <br />
              <br />
              <el-form ref="form" :model="form" :rules="dataRule">
                <div style="margin-bottom: 20px; float: left">
                  请输入账户名称
                </div>
                <el-form-item prop="account">
                  <el-input
                    v-model="form.account"
                    placeholder="请输入账户名称"
                  ></el-input>
                </el-form-item>
                <div style="margin-bottom: 20px; float: left">
                  请输入账户密码
                </div>
                <el-form-item prop="password">
                  <el-input
                    v-model="form.password"
                    placeholder="请输入账户密码"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click.native="extractGift()"
                    >下一步</el-button
                  >
                </el-form-item>
              </el-form></el-main
            >
          </el-container>
        </el-col>
      </el-row>
      <div class="empty"></div>
    </el-main>
  </el-container>
</template>
  <script>
export default {
  name: "submit-email",
  data() {
    return {
      spanValue: 0,
      offsetValue: 0,
      activeName: "first",
      showTable: false,
      form: {
        cdk: "",
        account: "",
        password: "",
      },
      dataRule: {
        cdk: [{ required: true, message: "兑换码不能为空", trigger: "blur" }],
        account: [{ validator: this.checkAccountName, trigger: "blur" }],
        password: [{ validator: this.checkPassword, trigger: "blur" }],
      },
    };
  },
  mounted: function () {
    if (this._isMobile()) {
      this.spanValue = 24;
      this.offsetValue = 0;
    } else {
      this.spanValue = 10;
      this.offsetValue = 7;
    }
  },
  created() {
    if (this.$route.query.cdk) {
      this.form.cdk = this.$route.query.cdk;
    }
  },
  methods: {
    checkAccountName(rule, value, callback) {
      if (value.length < 3 || value.length > 64) {
        callback(
          new Error(
            "请输入帐户名称（长度至少为 3 个字符，且仅使用 a-z、A-Z、0-9 或 _ 字符"
          )
        );
      } else {
        var bNameOK = true;
        for (var i = 0; i < value.length; ++i) {
          if (value.charAt(i) >= "a" && value.charAt(i) <= "z") continue;
          if (value.charAt(i) >= "A" && value.charAt(i) <= "Z") continue;
          if (value.charAt(i) >= "0" && value.charAt(i) <= "9") continue;
          if (value.charAt(i) == "_") continue;

          bNameOK = false;
        }
        if (!bNameOK) {
          callback(
            new Error(
              "请输入帐户名称（长度至少为 3 个字符，且仅使用 a-z、A-Z、0-9 或 _ 字符"
            )
          );
        } else {
          callback();
        }
      }
    },
    checkPassword(rule, value, callback) {
      if (
        this.form.account.length > 0 &&
        this.form.account.toLowerCase() == value.toLowerCase()
      ) {
        callback(new Error("用户名与密码不能相同"));
      } else if (value.length < 8) {
        callback(new Error("密码必须包含至少 8 个字符"));
      } else {
        var iInvalidChar = value.search(/[^\x00-\x7F]/g);
        if (iInvalidChar >= 0) {
          callback(
            new Error(
              "不能在密码中使用 %s".replace(
                /%s/,
                password_before.charAt(iInvalidChar)
              )
            )
          );
        } else {
          callback();
        }
      }
    },
    // 判断移动端还是pc端
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    extractGift() {
      console.log("click");
      this.$refs["form"].validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: "提交数据中",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          this.$http
            .post("guest/register", this.form)
            .then(({ data }) => {
              if (data && data.code === 0) {
                loading.close();
                if (!data.result.accountCanUse || !data.result.passwordCanUse) {
                  if (!data.result.accountCanUse) {
                    this.$refs.form.fields.find(
                      (item) => item.prop === "account"
                    ).validateState = "error";
                    this.$refs.form.fields.find(
                      (item) => item.prop === "account"
                    ).validateMessage =
                      "您选择的帐户名不可用。请选择一个不同的账户名。";
                  }
                  if (!data.result.passwordCanUse) {
                    this.$refs.form.fields.find(
                      (item) => item.prop === "password"
                    ).validateState = "error";
                    this.$refs.form.fields.find(
                      (item) => item.prop === "password"
                    ).validateMessage = "请选择一个比较不常见的密码。";
                  }
                } else {
                  this.$router.push({
                    path: "/complete",
                    query: {
                      cdk: this.form.cdk,
                    },
                  });
                }
              } else {
                loading.close();
                if (data.msg) {
                  this.$message.error(data.msg);
                } else {
                  this.$message.error("注册失败请重试");
                }
              }
            })
            .catch((e) => {
              loading.close();
              this.$message({
                message: "网络或程序异常！" + e,
                type: "error",
              });
            });
        }
      });
    },
  },
};
</script>
  
  <style>
body {
  background-image: url("../assets/background.jpg");
}
.el-header,
.el-footer {
  text-align: center;
  line-height: 80px;
}

.el-main {
  color: #b3c0d1;
  text-align: center;
}
#content {
  min-height: 230px;
  height: auto;
  box-sizing: border-box;
  background-color: #181a21;
  padding: 34px 42p;
}
.el-main span {
  text-align: left;
}
#main-col {
  background-color: #181a21;
  height: 250px;
}
/* body > .el-container {
      margin-bottom: 40px;
    } */

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
el-card {
  padding: 0px;
}
.empty,
el-footer {
  height: 100px;
}
</style>
  