<template>
  <el-container>
    <el-header height="180px"></el-header>
    <el-main>
      <el-row>
        <el-col>
          <h2>Steam自助注册</h2>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="spanValue" :offset="offsetValue">
          <el-container id="content">
            <el-main v-loading.fullscreen.lock="loading">
              <el-result icon="success" title="账号已注册成功"
                ><template slot="extra">
                  <div class="block-left-align">
                    <span style="color: #ff8f8fbd"
                      >注册邮箱: {{email}}</span
                    ><br />
                    <span style="color: #ff8f8fbd">注册地区: {{region}}</span><br />
                    <span style="color: #ff8f8fbd">账户名称: {{accountName}}</span
                    ><br />
                    <span style="color: #ff8f8fbd">账户密码: {{password}}</span
                    ><br />
                    <span style="color: #ff8f8fbd"
                      >注册时间: {{createTime}}</span
                    ><br /><br /><br />
                    <span style="color: #dc143c"
                      >为了您的账户安全,请及时修改您的密码！！！</span
                    >
                  </div>
                </template>
              </el-result>
            </el-main>
          </el-container>
        </el-col>
      </el-row>
      <div class="empty"></div>
    </el-main>
  </el-container>
</template>
    <script>
export default {
  name: "complete",
  data() {
    return {
      spanValue: 0,
      offsetValue: 0,
      activeName: "first",
      loading: false,
      showTable: false,
      remainder: "",
      form: {
        cdk: "",
      },
      region: '',
      accountName: '',
      password: '',
      email: '',
      createTime: null
    };
  },
  mounted: function () {
    if (this._isMobile()) {
      this.spanValue = 24;
      this.offsetValue = 0;
    } else {
      this.spanValue = 10;
      this.offsetValue = 7;
    }
    this.loading = true;
    if (!this.form.cdk) {
      window.location.href = "/"
    }
    this.$http
      .get("guest/checkCdk/" + this.form.cdk)
      .then(({ data }) => {
        if (data && data.code === 0) {
          this.loading = false;
          if (data.result.status === 1) {
            this.$router.push({
              path: "/submit-email",
              query: {
                cdk: this.form.cdk,
              },
            });
          } else if (data.result.status === 2) {
            this.$router.push({
              path: "/email-verify",
              query: {
                cdk: this.form.cdk,
              },
            });
          } else if (data.result.status === 3) {
            this.$router.push({
              path: "/fill-info",
              query: {
                cdk: this.cdk.cdk,
              },
            });
          } else if (data.result.status === 4) {
            this.accountName = data.result.account
            this.password = data.result.password
            this.region = data.result.region
            this.createTime = data.result.createTime
            this.email = data.result.email
          } else {
            this.$message.error("正在处理中...");
          }
        } else {
          this.loading = false;
          this.$message.error(data.msg);
          window.location.href = "/"
        }
      })
      .catch((e) => {
        this.loading = false;
        this.$message({
          message: "网络或程序异常！" + e,
          type: "error",
        });
      });
  },
  created() {
    if (this.$route.query.cdk) {
      this.form.cdk = this.$route.query.cdk;
    }
  },
  methods: {
    // 判断移动端还是pc端
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    onSubmit() {},
    handleClick(tab, event) {},
    extractGift() {
      this.$refs["form"].validate((valid) => {
        // if (valid) {
        //   this.loading = true;
        //   this.$http
        //     .post("guest/gift/exchange", this.form)
        //     .then(({ data }) => {
        //       if (data && data.code === 0) {
        //         this.loading = false;
        //         this.$message({
        //           message: "操作成功",
        //           type: "success",
        //           duration: 1500,
        //         });
        //       } else {
        //         this.loading = false;
        //         this.$message.error(data.msg);
        //       }
        //     })
        //     .catch((e) => {
        //       this.loading = false;
        //       this.$message({
        //         message: "网络或程序异常！" + e,
        //         type: "error",
        //       });
        //     });
        // }
        this.showtime();
      });
    },
  },
};
</script>
    
    <style>
body {
  background-image: url("../assets/background.jpg");
}
.el-header,
.el-footer {
  text-align: center;
  line-height: 80px;
}

.el-main {
  color: #b3c0d1;
  text-align: center;
}
#content {
  min-height: 230px;
  height: auto;
  box-sizing: border-box;
  background-color: #181a21;
  padding: 34px 42p;
}
.el-main span {
  text-align: left;
}
#main-col {
  background-color: #181a21;
  height: 250px;
}
/* body > .el-container {
        margin-bottom: 40px;
      } */

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
el-card {
  padding: 0px;
}
.empty,
el-footer {
  height: 100px;
}
.block-left-align {
  margin: 0;
  padding: 0;
  text-align: left;
  /* 如果需要，可以添加更多的内边距控制 */
}

.block-left-align :first-child {
  margin-left: 0;
}
</style>
    