<template>
  <el-container>
    <el-header height="180px"></el-header>
    <el-main>
      <el-row>
        <el-col>
          <h2>Steam自助注册</h2>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="spanValue" :offset="offsetValue">
          <el-container id="content">
            <el-main v-loading.fullscreen.lock="loading">
              <el-link
                target ="_blank"
                type="danger"
                :underline="false"
                href="https://www.bilibili.com/video/BV131421277r/?share_source=copy_web&vd_source=39369fb17f08034066f66058019fb03b"
                >点此查看视频教程</el-link>
                <br />
              <br />
              <el-alert
                title="链接开头为  https://store.steampowered.com/account/newaccountverification?stoken="
                type="warning"
              >
              </el-alert>
              <br />
              <br />
              <el-form ref="form" :model="form" :rules="dataRule">
                <div style="margin-bottom: 20px; float: left">
                  请输入邮箱收到的注册确认链接
                </div>
                <el-form-item prop="verifyEmailUrl">
                  <el-input
                    v-model="form.verifyEmailUrl"
                    placeholder="请输入邮箱收到的注册确认链接"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="extractGift()"
                    >下一步</el-button
                  >
                </el-form-item>
              </el-form></el-main
            >
          </el-container>
        </el-col>
      </el-row>
      <div class="empty"></div>
    </el-main>
  </el-container>
</template>
  <script>
export default {
  name: "email-verify",
  data() {
    return {
      spanValue: 0,
      offsetValue: 0,
      activeName: "first",
      loading: false,
      remainder: "",
      intervalBox: null,
      endTime: null,
      showForm: false,
      form: {
        cdk: "",
        verifyEmailUrl: "",
      },
      dataRule: {
        cdk: [
          { required: true, message: "兑换码不能为空", trigger: "blur" },
        ],
        verifyEmailUrl: [
          { required: true, message: "Steam注册确认链接不能为空", trigger: "blur" },
        ],
      },
    };
  },
  mounted: function () {
    if (this._isMobile()) {
      this.spanValue = 24;
      this.offsetValue = 0;
    } else {
      this.spanValue = 10;
      this.offsetValue = 7;
    }
  },
  created() {
    if (this.$route.query.cdk) {
      this.form.cdk = this.$route.query.cdk;
    }
  },
  methods: {
    showtime: function () {
      var nowtime = new Date(); //获取当前时间
      var lefttime = this.endTime.getTime() - nowtime.getTime(); //距离结束时间的毫秒数
      if (lefttime <= 0) {
        clearInterval(this.intervalBox);
        this.remainder = "商家正在处理中";
        return;
      }
      console.log(lefttime);
      var leftd = Math.floor(lefttime / (1000 * 60 * 60 * 24)); //计算天数
      var lefth = Math.floor((lefttime / (1000 * 60 * 60)) % 24); //计算小时数
      var leftm = Math.floor((lefttime / (1000 * 60)) % 60); //计算分钟数
      var lefts = Math.floor((lefttime / 1000) % 60); //计算秒数
      this.remainder =
        leftd + "天" + lefth + "时" + leftm + "分" + lefts + "秒"; //返回倒计时的字符串
    },
    // 判断移动端还是pc端
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    onSubmit() {},
    handleClick(tab, event) {},
    extractGift() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$http
            .post("guest/register", this.form)
            .then(({ data }) => {
              if (data && data.code === 0) {
                this.loading = false;
                this.$router.push({
                  path: "/fill-info",
                  query: {
                    cdk: this.form.cdk,
                  },
                });
              } else {
                this.loading = false;
                this.$message.error(data.msg);
              }
            })
            .catch((e) => {
              this.loading = false;
              this.$message({
                message: "网络或程序异常！" + e,
                type: "error",
              });
            });
        }
      });
    },
  },
};
</script>
  
  <style>
body {
  background-image: url("../assets/background.jpg");
}
.el-header,
.el-footer {
  text-align: center;
  line-height: 80px;
}

.el-main {
  color: #b3c0d1;
  text-align: center;
}
#content {
  min-height: 230px;
  height: auto;
  box-sizing: border-box;
  background-color: #181a21;
  padding: 34px 42p;
}
.el-main span {
  text-align: left;
}
#main-col {
  background-color: #181a21;
  height: 250px;
}
/* body > .el-container {
      margin-bottom: 40px;
    } */

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
el-card {
  padding: 0px;
}
.empty,
el-footer {
  height: 100px;
}
</style>
  