import Vue from 'vue'
import Router from 'vue-router'
import Index from '../components/index'
import SubmitEmail from '../components/submit-email'
import EmailVerify from '../components/email-verify'
import Complete from '../components/complete'
import FillInfo from '../components/fill-info'


Vue.use(Router)
const routes = [
  { path: '/', component: Index, name: 'idnex' },

  { path: '/submit-email', component: SubmitEmail, name: 'submit-email' },
  { path: '/email-verify', component: EmailVerify, name: 'email-verify' },
  { path: '/complete', component: Complete, name: 'complete' },
  { path: '/fill-info', component: FillInfo, name: 'fill-info' },


]
const router = new Router({
    mode: 'hash',
    routes,
    scrollBehavior: () => ({ y: 0 }),
    isAddDynamicMenuRoutes: false, // 是否已经添加动态(菜单)路由
  })
export default router
